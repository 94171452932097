<template>
  <div>
    <b-card>
      <h4 class="mb-2">
        My Profile
      </h4>

      <b-form @submit.prevent>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Full Name"
              label-for="h-full-name"
              label-cols-md="4"
              class="text-right"
            >
              <b-form-input
                id="h-full-name"
                :value="authUser.name"
                class="w-50"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Email"
              label-for="h-other-field"
              label-cols-md="4"
              class="text-right"
            >
              <b-form-input
                id="h-other-field"
                v-model="authUser.email"
                class="w-50"
                disabled
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Company Name"
              label-for="h-company-name"
              label-cols-md="4"
              class="text-right"
            >
              <b-form-input
                id="h-company-name"
                :value="authUser.subscription.ExternalAccountName"
                class="w-50"
                disabled
              />
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
            <b-form-group
              label="Number of Portfolios"
              label-for="h-number-of-portfolios"
              label-cols-md="4"
              class="text-right"
            >
              <b-form-input
                id="h-number-of-portfolios"
                value="54"
                class="w-50"
                disabled
              />
            </b-form-group>
          </b-col> -->

          <b-col cols="12">
            <b-row class="my-3">
              <b-col cols="4" />
              <b-col cols="8">
                <b-link
                  href="https://www.structuredretailproducts.com/account"
                  target="_blank"
                >
                  My SRP Account
                </b-link>
                <feather-icon icon="ExternalLinkIcon" />
              </b-col>
            </b-row></b-col>
        </b-row>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BForm,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BLink,
  },
  data() {
    return {
      name: 'Bereket Gobeze',
    }
  },
  computed: {
    authUser() {
      return this.$auth.user
    },
  },
}
</script>
